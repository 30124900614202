var staff = function () {
    var companyId = 1,
        search = { id: 0 },
        page = 1,
        isLastPage = false,
        lockedPreload = false,
        scroller;

    return {
        init: function (params, callback) {
            if (params.company_id) {
                companyId = params.company_id;
            }

            search.id = 0;
            page = 1;
            lockedPreload = false;
            isLastPage = false;

            if (typeof callback == 'function') {
                callback();
            }
        },
        preloadPage: function (structure, onlyVacancies) {
            if (isLastPage) {
                return false;
            }

            lockedPreload = true;

            _ajax.get('/team/ajax/preloadPage/', {company: companyId, page: page++, structure: structure }, function (html, status, data) {
                if (html) {
                    $("#content-page-company").html(html);
                }

                var i, result, e, positions, $workerBlock, somethingFounded = false;

                if (data.employees && !onlyVacancies) {
                    for (i in data.employees) {
                        e = data.employees[i];

                        positions = e.positions;
                        result = staff.employeeTempl(e);

                        if (positions) {
                            for (var j = 0; j < positions.length; j++) {
                                // если смотрим группы, то не выводим сотрудников без группы
                                // if (group && positions[j].group == 0 && positions[j].crew == 0) {
                                //     continue;
                                // }

                                $workerBlock = $('#structure-' + positions[j].department + '-' + positions[j].group + '-' + positions[j].crew + ' .workers_block:first');

                                $workerBlock.prepend(
                                    result.replace('{position}', positions[j].position)
                                        .replace('{css}', positions[j].cssClass)
                                        .replace('{order}', positions[j].order)
                                ).find('.worker-popup-span').sort(function (a, b) {
                                    // сортировка по вложенности и порядку должности
                                    return +a.getAttribute('data-order') - +b.getAttribute('data-order');
                                }).prependTo($workerBlock);

                                $workerBlock.removeClass('h');
                                $workerBlock.parents('.structure-container').removeClass('h');
                            }
                        }
                    }

                    if (!structure) {
                        // пагинация
                        $('.load-more-employees').remove();
                        $('.worker-popup-span[data-id="' + data.employees[data.employees.length - 1].id + '"]:first').after('<div class="load-more-employees"></div>');

                        if (scroller) {
                            scroller.destroy();
                        }

                        if ($('.load-more-employees').length) {
                            scroller = new Scroller({
                                scrollTo: '.load-more-employees',
                                onScroll: function(me){
                                    if (!lockedPreload) {
                                        staff.preloadPage();
                                        me.destroy();
                                    }
                                }
                            });
                        }
                    }

                    if (data.employees.length) {
                        somethingFounded = true;
                    }
                } else {
                    isLastPage = true;
                }

                if (data.vacancies.length) {
                    for (i in data.vacancies) {
                        e = data.vacancies[i];
                        result = staff.employeeTempl(e);

                        $workerBlock = $('#structure-' + e.department_id + '-' + e.group_id + '-' + e.crew_id + ' .workers_block:first');

                        $workerBlock.removeClass('h').append(
                            result.replace('{position}', e.position)
                                .replace('{css}', e.cssClass)
                                .replace('{order}', e.order)
                        ).find('.worker-popup-span').sort(function (a, b) {
                            // сортировка по вложенности и порядку должности
                            return +a.getAttribute('data-order') - +b.getAttribute('data-order');
                        }).appendTo($workerBlock);

                        $workerBlock.removeClass('h');
                        $workerBlock.parents('.structure-container').removeClass('h');
                    }

                    if (data.vacancies.length) {
                        somethingFounded = true;
                    }
                }

                // if (html && !somethingFounded) {
                //     $("#content-page-company").html('<div class="p-10 center muted pt-10">' +
                //         '<p class="center">' +
                //             '<span class="banner-not-found"></span>' +
                //         '</p>' +
                //         '<p class="center p-10 muted">\n' +
                //             fsLang.g('К сожалению, никого не найдено') +
                //         '</p>' +
                //     '</div>');
                // }

                lockedPreload = false;
            });
        },
        downloadInfoCompanyPopup: function (companyId) {
            _ajax.post('/team/ajax/downloadCompanyPopup/', {"company_id" : companyId}, function (html, status, data, title) {
                if (!status) {
                    fs.notify('У вас недостаточно прав для этого действия.');
                    return false;
                }

                iPopup.confirm(html,[{
                    title: fsLang.g('Выгрузить'),
                    callback : function (e) {
                        var selectedFieldsElements = $('.staff-fields-item.selected'), fields = [];
                        $.each(selectedFieldsElements, function () {
                            fields.push($(this).data('field'));
                        });

                        if (!companyId) {
                            fs.notify('Сначала выберите компанию.');
                            return false;
                        }

                        $('#staff-fields').val(fields);
                        $('#download-form').submit();
                        fs.notify('Данные успешно загружены');

                        return false;
                    }
                }, {
                    'title': fsLang.g('Отмена')
                }], {
                    title: title
                });
            });
        },
        downloadAchievesPopup: function (companyId) {
            _ajax.post('/team/achievesAjax/downloadPopup/', {company_id: companyId}, function (html, status, data, title) {
                if (!status) {
                    fs.notify('У вас недостаточно прав для этого действия.');
                    return false;
                }

                iPopup.confirm(html,[{
                    preventClose: true,
                    title: fsLang.g('Выгрузить'),
                    callback: function (me) {
                        var $form = $('#export_form');

                        if (!$form.find('#startDate').val().length || !$form.find('#endDate').val().length) {
                            fs.notify('Проверьте правильность дат.');
                            return false;
                        }

                        if (!$('.event-types .form-checkbox.checked').length) {
                            fs.notify('Выберите хотя бы один тип.');
                            return false;
                        }

                        $form.submit();
                        me.close();
                    }
                }, {
                    'title': fsLang.g('Отмена')
                }], {
                    title: title,
                    noOverlayClose: true,
                    preventClose: true
                });
            });
        },
        chooseContact: function(element) {
            var $element = $(element),
                staff_id = $element.val();

            _ajax.get('/team/ajax/chooseContactPopup/', {"staff_id": staff_id} , function(html, status, data, title) {
                if (!status) {
                    fs.notify('У вас недостаточно прав для этого действия.');
                    return false;
                }
                iPopup.closeAll();
                iPopup.confirm(html,  [{
                    title: fsLang.g('Сохранить'),
                    callback: function (me) {
                        var options = $('#staffpeople-scrollbar-submit .item:first'),
                            staff_id = options.data('id'),
                            staff_ava_small = options.data('ava-small'),
                            staff_name = options.data('name');

                        if (options.length) {
                            staff_id = parseInt(staff_id);
                            $element.val(staff_id);
                            $('#staff_ava_small').attr('style', 'background: url(' + staff_ava_small + ') no-repeat center');
                            $('#staff_name').text(staff_name);
                        }
                    }
                }, {
                    title: fsLang.g('Отмена')
                }], {
                    title: title,
                    noOverlayClose: true,
                    onopen: function(me) {
                        staff.popupEmployees.setPopup(me);
                    }
                });
            });
        },
        searchEmployees: function() {
            return  {
                do: function (params, requestFunction) {
                    var queryParams = nav2.getParam('s') || {},
                        redirectToMain = function() {
                            var redirectParams = [];

                            for (var i in queryParams) {
                                redirectParams.push('s[' + i + ']=' + encodeURIComponent(queryParams[i]));
                            }

                            nav2.go('/company/' + companyId + '/?' + redirectParams.join('&'));
                        };

                    $.extend(queryParams, params);

                    if (queryParams.hasOwnProperty('q') && !$.trim(queryParams.q).length) {
                        delete queryParams.q;
                    }

                    if (queryParams.hasOwnProperty('with_dismissed') && queryParams.with_dismissed == 0) {
                        delete queryParams.with_dismissed;
                    }

                    if ($.isEmptyObject(queryParams)) {
                        page = 1;
                        isLastPage = false;

                        nav2.deleteParam('s');

                        if (typeof requestFunction == 'function') {
                            requestFunction(null);
                        } else {
                            if (nav2.location.pathname != '/company/' + companyId + '/') {
                                redirectToMain();
                                return;
                            }

                            if (scroller) {
                                scroller.destroy();
                            }

                            staff.preloadPage();
                        }

                        return;
                    }

                    nav2.setParam('s', queryParams, true);

                    staff.searchEmployees.loading();

                    if (typeof requestFunction == 'function') {
                        requestFunction(queryParams, search);
                    } else {
                        if (nav2.location.pathname != '/company/' + companyId + '/') {
                            redirectToMain();
                            return;
                        }

                        if (scroller) {
                            scroller.destroy();
                        }

                        _ajax.get('/team/ajax/searchEmployees/', { company_id: companyId, search_id: search.id, search: queryParams }, function (html, status, data, title) {
                            if (status) {
                                staff.searchEmployees.loading(true);

                                AnyTooltipGc();

                                // чтобы более ранний запрос не переписал более поздний ответ
                                if (data.searchId == search.id) {
                                    $("#content-page-company").html(html);
                                    search.id++;
                                }
                            }
                        });
                    }
                },
                clear: function() {
                    if (fsSelect.cache['#select-structure']) {
                        fsSelect.cache['#select-structure'].clean();
                    }

                    if (fsSelect.cache['#select-position']) {
                        fsSelect.cache['#select-position'].clean();
                    }

                    $('#search-with-dismissed').removeClass('checked');

                    nav2.deleteParam('s');

                    $('#search-text').val('').trigger('input').focus();
                },
                loading: function(isLoaded) {
                    if (isLoaded) {
                        $('.team_top_block .icn-cross').removeClass('h');
                        $('.team_top_block .icn-process').addClass('h');
                    } else {
                        $('.team_top_block .icn-cross').addClass('h');
                        $('.team_top_block .icn-process').removeClass('h');
                    }
                }
            };
        }(),
        sendPassword: function(employeeId) {
            iPopup.confirm(fsLang.s('Вы действительно хотите выслать пароль на %semail%s? Если у сотрудника уже существует пароль, он будет сброшен.', ['<strong>', '</strong>']), [{
                    title: fsLang.g('Сбросить и отправить'),
                    callback: function () {
                        _ajax.post('/team/ajax/sendPassword/', { staffId: employeeId }, function (html, status, data) {
                            if (status) {
                                fs.notify('Пароль успешно сгенерирован и отправлен.');
                            }
                        });
                    }
                }, {
                    'title': fsLang.g('Отмена')
                }]
            );
        },
        dismissedEmployee: function (el, employeeId, companyId) {
            var reinstated = $(el).data('reinstated');
            if (reinstated) {
                _ajax.get('/team/ajax/dismissedEmployee/', {id: employeeId, company_id: companyId, reinstated: 1 }, function (html, status, data) {
                    if (status) {
                        fs.notify('Сотрудник восстановлен.');
                        if ($(el).hasClass('icn')) {
                            $(el).addClass('icn-light-gray').data('reinstated', 0);
                        } else {
                            $(el).addClass('red').text(fsLang.g('Уволить')).data('reinstated', 0);
                        }

                        $('.dissmiss-employee').addClass('h');
                    } else {
                        fs.notify('У вас недостаточно прав для этого действия.');
                    }
                });
            } else {
                _ajax.get('/team/ajax/dismissedPopup/', {employee_id: employeeId, company_id: companyId }, function (html, status, data, title) {
                    if (status) {
                        iPopup.confirm(html, [{
                                title: fsLang.g('Уволить'),
                                callback: function () {
                                    var date = $('#staff_date_dissmissed');
                                    _ajax.get('/team/ajax/dismissedEmployee/', {"id" : employeeId, "company_id": companyId, "date" : date.val()}, function (html, status, data) {
                                        if (status) {
                                            fs.notify('Сотрудник уволен.');
                                            if ($(el).hasClass('icn')) {
                                                $(el).removeClass('icn-light-gray').data('reinstated', 1);
                                            } else {
                                                $(el).removeClass('red').text(fsLang.g('Восстановить в должности')).data('reinstated', 1);
                                            }

                                            $('.dissmiss-employee').removeClass('h');
                                        } else {
                                            fs.notify('У вас недостаточно прав для этого действия.');
                                        }
                                    });
                                }
                            }, {
                                'title': fsLang.g('Отмена')
                            }], {
                                title: title
                            }
                        );
                    } else {
                        fs.notify('У вас недостаточно прав для этого действия.');
                    }
                });
            }

            return false;
        },
        editAccess: function(id) {
            _ajax.get('/admin/ajax/accessPopup/', {id: id}, function (html, status, data, title) {
                if (status) {
                    iPopup.open(html, {
                        title: title
                    });
                }
            });

            return false;
        },
        deleteEmployee: function (employeeId) {
            iPopup.confirm(fsLang.g('Для подтверждения удаления нажмите "Да".'), [{
                callback: function () {
                    _ajax.get('/team/ajax/deleteEmployee/', {"employee_id" : employeeId}, function (html, status, data) {
                        if (status) {
                            fs.notify('Сотрудник удалён');
                            $('.employee[data-id="' + employeeId + '"]').slideUp(300);
                        } else {
                            fs.notify('У вас недостаточно прав для этого действия.');
                        }
                    });
                }
            }],{
                title: fsLang.g('Удалить сотрудника из Базы Данных')
            });
        },
        deleteVacancy: function (id) {
            iPopup.confirm(fsLang.g('Для подтверждения удаления нажмите "Да".'), [{
                callback: function () {
                    nav2.go('/vacancies/delete/' + id + '/');
                }
            }],{
                title: fsLang.g('Удалить вакансию из Базы Данных')
            });

            return false;
        },
        showInfoLoader: null,
        showInfoTooltips: {},
        showInfoPosition: function (element, params) {
            var self = this,
                key = Object.keys(params)[0],
                id = params[key];

            self.showInfoLoader = st(function() {
                if (id && self.showInfoTooltips[id]) {
                    if (self.showInfoTooltips[id] != 'err') {
                        AnyTooltipGc();
                        helpTooltip.show(element, self.showInfoTooltips[id]);
                    }
                } else {
                    _ajax.get('/team/ajax/employee/', params, function(html, status, data) {
                        if (status) {
                            AnyTooltipGc();
                            helpTooltip.show(element, html);
                            self.showInfoTooltips[id] = html;
                        } else {
                            helpTooltip.hide(element);
                            self.showInfoTooltips[id] = 'err';
                        }
                    });
                }
            }, 500);
        },
        showInfoEmployee: function (id, company_id) {
            _ajax.get('/team/ajax/employee/', {id: id, company_id: company_id, detail: 1, popup: 1}, function(html, status, data){
                if (status) {
                    AnyTooltipGc();
                    iPopup.open(html, {
                        headerControls: data.controls || [],
                        style: {
                            myclass: 'workgroup-popup',
                            width: 670
                        },
                        onopen: function() {
                            nav2.setParam('popup_employee', id, true);
                        },
                        onclose: function() {
                            nav2.deleteParam('popup_employee', true);
                        }
                    });
                }
            });

            return false;
        },
        hideInfoPosition: function (element) {
            if (this.showInfoLoader) {
                clearTimeout(this.showInfoLoader);
            }

            helpTooltip.hide(element);
        },
        infoLegalEntityPopup : function (id) {
            _ajax.get('/team/ajax/infoLegalEntityPopup/', {id: id}, function (html, status, data, title) {
                if (status) {
                    iPopup.open(html, {title: title, noOverlayClose: true, style: { width:  600}});
                } else {
                    fs.notify('У вас недостаточно прав для этого действия.');
                }
            });
        },
        sidebarScrollbarInstances: [],
        sidebarScrollbar: function (id) {
            var $el = $('#' + id);

            if ($el.parent().css('position') != 'fixed') {
                if (staff.sidebarScrollbarInstances[id]) {
                    $el.find('.content-scrollbar-inner').css('height', '');
                    staff.sidebarScrollbarInstances[id].update(false, true);
                }

                return false;
            }

            // обновляем высоту
            $el.find('.content-scrollbar-inner').height(fs.windowH - $el.offset().top + fs.scrollTop);

            // обновляем/создаем сайдбар
            if (!staff.sidebarScrollbarInstances[id]) {
                staff.sidebarScrollbarInstances[id] = new Scrollbar(id);
                $el.off('mouseenter.' + id).on('mouseenter.' + id, function () {
                    $(this).find('.scrollbar-wrap').fadeIn();
                }).off('mouseleave.' + id).on('mouseleave.' + id, function () {
                    $(this).find('.scrollbar-wrap').fadeOut();
                });

                var $win = $(window),
                    timeout = null;
                $win.off('resize.' + id).on('resize.' + id, function () {
                    clearTimeout(timeout);
                    timeout = st(function () {
                        staff.sidebarScrollbar(id);
                    }, 250);
                });

                $('#team-toggle-icon').click(function () {
                    staff.sidebarScrollbar(id);
                });

                nav2.gc(function () {
                    staff.sidebarScrollbarInstances[id] = null;
                    $win.off('resize.' + id);
                });
            } else {
                staff.sidebarScrollbarInstances[id].update(false, true);
                $el.find('.scrollbar-wrap').hide();
            }
        },
        changeStatus: function(id) {
            _ajax.get('/team/ajax/statusPopup/', {"id" : id }, function (html, status, data, title) {
                if (status) {
                    iPopup.confirm(html, [{
                        title: fsLang.g('Сохранить'),
                        callback: function () {
                            var form = $('#status-form');

                            _ajax.post(form.attr('action'), form.serialize(), function (html, status, data) {
                                if (status) {
                                    fs.notify('Изменения сохранены.');
                                    $('#staff-status').text(data.status);
                                } else {
                                    fs.notify('У вас недостаточно прав для этого действия.');
                                }
                            });
                        }}, {
                            title: fsLang.g('Очистить'),
                            callback: function () {
                                var form = $('#status-form');

                                form.find('#status').val('');

                                _ajax.post(form.attr('action'), form.serialize(), function (html, status, data) {
                                    if (status) {
                                        $('#staff-status').empty();
                                    } else {
                                        fs.notify('У вас недостаточно прав для этого действия.');
                                    }
                                });
                            }
                        }], {
                            noOverlayClose: true,
                            title: title
                        }
                    );
                } else {
                    fs.notify('У вас недостаточно прав для этого действия.');
                }
            });

            return false;
        },
        employeeTempl: function (user, showContacts) {
            return [
                '<div data-id="' + user.id + '" data-order="{order}" class="worker-popup-span" ' + (!showContacts ? 'onmouseenter="staff.showFullWorkerBlock(this, event)" onmouseleave="staff.hideFullWorkerBlock(this, event)"' : '') + '>',
                    '<div class="worker-user-item">',
                        '<div class="worker-user-info">',
                            '<div class="worker-user-back {css}"></div>',
                            '<a href="' + user.url + '" class="relative inline-block worker-user-image">' +
                                '<img src="' + user.image + '" class="staff-ava" />' +
                                (user.online ? '<span class="is-online on-image"></span>' : '') +
                                (user.vacation ? '<span class="worker-user-info-left-corner image-icon-wrapper"><i class="icn ' + user.vacation.icon + '" onmouseover="helpTooltip.show(this, \'' + user.vacation.title + '\');" onmouseout="helpTooltip.hide(this);"></i></span>' : ''),
                                (user.remote && !user.vacation ? '<span class="worker-user-info-left-corner image-icon-wrapper"><i class="icn icn-light-gray icn-guest" onmouseover="helpTooltip.show(this, \'' + fsLang.g('Работает удалённо') + '\');" onmouseout="helpTooltip.hide(this);"></i></span>' : ''),
                                (user.maternity && !user.vacation ? '<span class="worker-user-info-left-corner image-icon-wrapper"><i class="icn icn-light-gray icn-maternity" onmouseover="helpTooltip.show(this, \'' + fsLang.g('В декретном отпуске') + '\');" onmouseout="helpTooltip.hide(this);"></i></span>' : ''),
                                (user.on_work ? '<span class="worker-user-info-right-corner image-icon-wrapper"><i class="icn icn-approve" onmouseover="helpTooltip.show(this, \'' + fsLang.g('Сейчас на работе') + '\');" onmouseout="helpTooltip.hide(this)"></i></span>' : ''),
                            '</a>',
                            '<div class="worker-user-detail">' +
                                '<a class="worker-user-name ellipsis" href="' + user.url + '">' + user.name.replace(' ', '<br/>') + '</a>',
                                '<span class="worker-user-post ellipsis">',
                                    '{position}&nbsp;',
                                '</span>',
                            '</div>' +
                        '</div>',
                        '<div class="worker-user-subinfo ' + (showContacts ? 'worker-user-subinfo-show' : '') + '">',
                            (user.phone ? '<span class="icon-wrapper dropdown-item unselectable" onclick="staff.showContact(this,  \'phone\', \'' + user.phone + '\', event)" onmouseleave="ddb.onmouseleave(this, event);"><i class="worker-icon worker-icon-phone"></i></span>' : ''),
                            (user.mail ? '<span class="icon-wrapper dropdown-item unselectable" onclick="staff.showContact(this,  \'mail\', \'' + user.mail + '\', event)" onmouseleave="ddb.onmouseleave(this, event);"><i class="worker-icon worker-icon-email"></i></span>' : ''),
                            (user.slack  ? '<span class="icon-wrapper dropdown-item unselectable" onclick="staff.showContact(this,  \'slack\', \'' + user.slack + '\', event)" onmouseleave="ddb.onmouseleave(this, event);"><i class="worker-icon worker-icon-slack"></i></span>' : ''),
                            (user.skypeBusiness  ? '<span class="icon-wrapper dropdown-item unselectable" onclick="staff.showContact(this,  \'skype-business\', \'' + user.skypeBusiness + '\', event)" onmouseleave="ddb.onmouseleave(this, event);"><i class="worker-icon worker-icon-skype-business" title="Skype4Business"></i></span>' : ''),
                            (!user.skypeBusiness && user.skype  ? '<span class="icon-wrapper dropdown-item unselectable" onclick="staff.showContact(this,  \'skype\', \'' + user.skype + '\', event)" onmouseleave="ddb.onmouseleave(this, event);"><i class="worker-icon worker-icon-skype"></i></span>' : ''),
                        '</div>',
                    '</div>',
                '</div>'
            ].join('');
        },
        showContact: function(el, type, contact, event) {
            if (!$(event.target).hasClass('icon-wrapper') && !$(event.target).hasClass('worker-icon')) {
                return;
            }

            var icon, text, link;

            $('.worker-popup-span').find('.dropdown-item').find('.dropdown-box').remove();

            switch (type) {
                case 'phone':
                    icon = 'worker-icon-phone';
                    text = fsLang.g('Позвонить');
                    link = "tel:{contact}";
                    break;
                case 'mail':
                    icon = 'worker-icon-email';
                    text = fsLang.g('Отправить письмо');
                    link = "mailto:{contact}";
                    break;
                case 'slack':
                    var sub = contact.split(':');
                    icon = 'worker-icon-slack';
                    text = fsLang.g('Написать в Slack');
                    link = "slack://channel?team=" + sub[0] + "&id=" + sub[1];
                    contact = sub[2];
                    break;
                case 'skype':
                    icon = 'worker-icon-skype';
                    text = fsLang.g('Написать в Skype');
                    link = "skype:{contact}?chat";
                    break;
                case 'skype-business':
                    icon = 'worker-icon-skype-business';
                    text = fsLang.g('Написать в Skype');
                    link = "skype:{contact}?chat";
                    break;
            }

            $(el).append('<div class="dropdown-box">' +
                '<span class="icon-fake-wrapper"><i class="worker-icon ' + icon + '"></i></span>' +
                '<span class="ddb-item strong">' + contact + '</span>' +
                '<span class="ddb-item copy-contact">' + fsLang.g('Скопировать') + '</span>' +
                '<a class="ddb-item" href="' + link.replace('{contact}', contact) + '" onclick="ddb.onmouseleave(this.parentNode.parentNode, event)">' + text + '</a>' +
            '</div>');

            require(['clipboard'], function(Clipboard) {
                var clipboard = new Clipboard($(el).find('.copy-contact')[0], {
                    text: function() { return contact; }
                });

                clipboard.on('success', function (e) {
                    fs.notify('Контакт скопирован в буфер обмена');
                    ddb.onmouseleave(el);
                });
            });

            ddb.onclick(el, event);
        },
        showInterestsPopup: function(interest) {
            _ajax.get('/team/ajax/showInterestsInPopup/', {interest: interest}, function(html, status, data, title) {
                if (!status || !html) {
                    fs.notify('У вас недостаточно прав для этого действия.');
                    return;
                }

                iPopup.open(html, {
                    title: title,
                    style:{
                        width: 710
                    },
                    footer: ''
                });
            });
        },
        achieves: {
            getDockPopup: function(id, company_id) {
                _ajax.get('/team/achievesAjax/dockPopup/', { staff_id: id, company_id: company_id }, function(html, status, data, title) {
                    if (!status) {
                        fs.notify('У вас недостаточно прав для этого действия.');
                        return;
                    }

                    iPopup.open(html, {
                        title: title,
                        style:{
                            width: 710
                        },
                        footer: ''
                    });
                });
            },
            dock: function (el, staff_id, id) {
                var $this = $(el);

                // заберем обычную ачивку
                if ($this.hasClass('ibtn-red')) {
                    _ajax.post('/team/achievesAjax/undock/', {staff_id: staff_id, achieve_id: id }, function (html, status) {
                        if (!status) {
                            fs.notify('У вас недостаточно прав для этого действия.');
                            return;
                        }

                        $this.attr('disabled', true);
                        fs.notify('Достижение забрано.');
                    });

                    return false;
                }

                _ajax.post('/team/achievesAjax/dock/', {staff_id: staff_id, achieve_id: id }, function (html, status) {
                    if (!status) {
                        fs.notify('У вас недостаточно прав для этого действия.');
                        return;
                    }

                    $this.attr('disabled', true);
                    fs.notify('Достижение выдано.');
                });
            },
            getMultiDockPopup: function(id) {
                _ajax.get('/team/achievesAjax/multiDockPopup/', {achieve_id: id } , function(html, status, data, title) {
                    if (!status) {
                        fs.notify('У вас недостаточно прав для этого действия.');
                        return false;
                    }
                    iPopup.closeAll();
                    iPopup.confirm(html,  [{
                        title: fsLang.g('Сохранить'),
                        callback: function (me) {
                            var options = {};

                            $('#staffpeople-scrollbar-submit .item').each(function(i,e) {
                                options[$(e).data('id')] = e.value;
                            });

                            _ajax.post('/team/achievesAjax/multiDock/', {achieve_id: id, options: options}, function(html, status, data) {
                                if (status) {
                                    fs.notify('Изменения сохранены.');
                                    nav2.reload();
                                }

                                return status;
                            });
                        }
                    }, {
                        title: fsLang.g('Отмена')
                    }], {
                        title: title,
                        noOverlayClose: true,
                        onopen: function(me) {
                            staff.popupEmployees.setPopup(me);
                            staff.popupEmployees.prepareFooter(fsLang.g('Бейдж будет изменен у'), '', ['сотрудников', 'сотрудника', 'сотрудников', 'сотрудников']);
                        }
                    });
                });
            },
            showAllEmployees: function (id) {
                if (!id) {
                    fs.notify('У вас недостаточно прав для этого действия.');
                    return false;
                }

                _ajax.get('/team/achievesAjax/getEmployeesByAchieves/', {achieve_id: id}, function (html, status, data, title){
                    if (!status) {
                        fs.notify('У вас недостаточно прав для этого действия.');
                        return false;
                    }

                    AnyTooltipGc();
                    iPopup.open(html, {
                        title: title,
                        style: {
                            width: 710
                        }
                    });

                });
            },
            getAchievesPopup: function(userId, companyId) {
                _ajax.get('/team/achievesAjax/getAchievesPopup/', {employee_id: userId, company_id: companyId}, function (html, status, data, title) {
                    AnyTooltipGc();
                    iPopup.open(html, {
                        title: title,
                        style: {
                            myclass: 'staff-new-achieves-wrapper',
                            width: 640
                        }
                    });
                });
            },
            getNewAchievesPopup: function() {
                _ajax.get('/team/achievesAjax/getNewAchievesPopup/', {}, function (html, status, data, title) {
                    AnyTooltipGc();
                    iPopup.open(html, {
                        title: title,
                        style: {
                            myclass: 'staff-new-achieves-wrapper',
                            width: 660
                        }
                    });
                });
            },
            reminderPopup: function() {
                _ajax.get('/team/achievesAjax/reminderPopup/', {}, function (html, status, data, title) {
                    AnyTooltipGc();
                    iPopup.open(html, {
                        title: title,
                        style: {
                            myclass: 'staff-new-achieves-wrapper',
                            width: 660
                        }
                    });
                });
            },
            showTooltips: {},
            getTooltip: function (element, params) {
                var self = this,
                    id = Object.values(params).join('_');

                if (id && self.showTooltips[id]) {
                    if (self.showTooltips[id] != 'err') {
                        AnyTooltipGc();
                        AnyTooltip.show(element, { content: { html: self.showTooltips[id]}});
                    }
                } else {
                    _ajax.get('/team/achievesAjax/getTooltip/', params, function(html, status){
                        if (status) {
                            AnyTooltipGc();
                            AnyTooltip.show(element, { content: { html: html}});
                            self.showTooltips[id] = html;
                        } else {
                            AnyTooltip.hide(element);
                            self.showTooltips[id] = 'err';
                        }
                    });
                }
            }
        },
        toggleGroupBlock: function(el) {
            var $icon = $(el);

            $icon.toggleClass('worker-icon-collapse-up worker-icon-collapse-down');

            if ($icon.hasClass('worker-icon-collapse-up')) {
                $icon.closest('.team-group-container').find(".worker-group-inner").slideDown();
            } else {
                $icon.closest('.team-group-container').find(".worker-group-inner").slideUp();
            }
        },
        showFullWorkerBlock: function (el) {
            if ($(el).hasClass('worker-popup-span-full')) {
                return;
            }

            $('.worker-popup-span-full').remove();

            var $fullBlock = $(el).clone();

            $fullBlock.addClass('worker-popup-span-full h');
            $fullBlock.find('.worker-user-post').removeClass('ellipsis');
            $fullBlock.find('.staff-ava').on('load', function() {
                $fullBlock.removeClass('h');
            });

            $(el).append($fullBlock);
        },
        hideFullWorkerBlock: function (el) {
            if (!$(el).hasClass('worker-popup-span-full')) {
                return;
            }

            $(el).remove();
        },
        showStructureDescription: function(id) {
            _ajax.get('/team/ajax/structureDescriptionPopup/', { id: id }, function(html, status, data, title){
                if (status) {
                    AnyTooltipGc();
                    iPopup.open(html, {
                        title: title,
                        style: {
                            width: 600
                        }
                    });
                }
            });

            return false;
        },
        popupEmployees: function() {
            var $currentPopup,
                currentEmployee,
                funcId, checkId, scrollbar,
                searchTimeout, searchText, searchFilter,
                footerLabel, footerNoLabel,
                footerPlural = [],
                manualCheckedId = [];

            return {
                setPopup: function(popup) {
                    $currentPopup = $('#' + popup.uname + popup.uid);
                },
                init: function (fid, cid) {
                    funcId = fid;
                    checkId = cid;

                    manualCheckedId = [];
                    if (!$currentPopup) {
                        $currentPopup = $('.iPopup:last');
                    }

                    searchText = $.trim($currentPopup.find('#search-employees').val());
                    searchFilter = $('#search-employees-filter .small-tab.on').data('filter');

                    currentEmployee = -1;
                    scrollbar = null;
                    this.scrollbar();

                    $currentPopup.find('.staff-company-item').hover(function() {
                        $(this).find('.staff-checker-company').removeClass('h');
                    }, function() {
                        $(this).find('.staff-checker-company').addClass('h');
                    });

                    $currentPopup.find('.staff-company-item:visible:first').trigger('click');

                    this.focus();
                },
                scrollbar: function () {
                    if (!scrollbar) {
                        scrollbar = new Scrollbar('staffpeople-scrollbar-wrap');
                    }

                    return scrollbar;
                },
                get: function (id, callback) {
                    var w, e,
                        $wrapper,
                        text = $.trim($currentPopup.find('#search-employees').val()),
                        filter = $currentPopup.find('#search-employees-filter .small-tab.on').data('filter');

                    if (id) {
                        $wrapper = $currentPopup.find('#staff-company-wrapper-' + id + ' .staff-people-wrapper');
                    }

                    currentEmployee = -1;
                    $currentPopup.find('.staff-people-item.selected').removeClass('selected');
                    $currentPopup.find('#empty-authors').addClass('h');

                    if ($wrapper) {
                        $wrapper.closest('.staff-company-wrapper').find('.icn-process').removeClass('h');
                    }

                    _ajax.post('/team/ajax/getPopupEmployees/', { companyId: id, text: text, checkFuncId: funcId, checkId: checkId, filter: filter, manualChecked: manualCheckedId }, function (html, status, data) {
                        if (status) {
                            if (data.employees.length) {
                                var foundSomeone = false;

                                for (var i = 0; i < data.employees.length; i++) {
                                    e = data.employees[i];

                                    for (var j = 0; j < e.companies.length; j++) {
                                        w = (text || !$wrapper) ? $currentPopup.find('#staff-company-wrapper-' + e.companies[j]).find('.staff-people-wrapper') : $wrapper;

                                        if (w.length) {
                                            foundSomeone = true;
                                            w.removeClass('h').append(staff.popupEmployees.render(e));
                                            w.closest('.staff-company-wrapper').removeClass('h');
                                            break;
                                        }
                                    }
                                }

                                if (!foundSomeone) {
                                    $('#empty-authors').removeClass('h');
                                }
                            } else {
                                if (!text && $wrapper) {
                                    $wrapper.html('<div class="muted center p-ver">' + fsLang.g('К сожалению, никого не найдено') + '.</div>').removeClass('h');
                                } else {
                                    $('#empty-authors').removeClass('h');
                                }
                            }

                            staff.popupEmployees.scrollbar().update();
                            staff.popupEmployees.updateSelected();
                        }

                        if (typeof callback != 'undefined') {
                            callback();
                        }

                        if ($wrapper) {
                            $wrapper.closest('.staff-company-wrapper').find('.icn-process').addClass('h');
                        }
                    });
                },
                search: function (e) {
                    var text,
                        $items = $currentPopup.find('.staff-people-item:visible');

                    if ((e.keyCode == 40 || // down
                        e.keyCode == 38) // up
                    ) {
                        if (e.keyCode == 40 && $items.length - 1 > currentEmployee) {
                            currentEmployee = currentEmployee + 1;
                        } else if (e.keyCode == 38 && currentEmployee != 0) {
                            currentEmployee = currentEmployee - 1;
                        }
                        $items.removeClass('selected').eq(currentEmployee).addClass('selected');
                        if ($items.length) {
                            this.scrollbar().scrollToY(this.scrollbar().el.scrollTop + $items.eq(currentEmployee).position().top - this.scrollbar().scrollHeight / 2 + $items.eq(currentEmployee).height() / 2);
                        }
                    } else if (e.keyCode == 32 && $items.filter('.selected').length) { // space
                        staff.popupEmployees.subscribe($items.filter('.selected')[0], null, !!$items.filter('.selected').data('multiselect'));
                        e.preventDefault();
                        return false;
                    } else if (e.keyCode == 13) { // enter
                        if (!confirm(fsLang.g('Сохранить изменения?'))) {
                            e.stopPropagation();
                        }
                    } else {
                        clearTimeout(searchTimeout);
                        searchTimeout = st(function() {
                            text = $.trim($currentPopup.find('#search-employees').val());

                            var filter = $currentPopup.find('#search-employees-filter .small-tab.on').data('filter');

                            if (searchText != text) {
                                $currentPopup.find('.staff-people-wrapper').empty();
                                searchText = text;

                                if (!text) {
                                    var $firstWrapper = (filter == 'sub') ? $currentPopup.find('#staff-company-wrapper-sub') : $currentPopup.find('.staff-company-wrapper:not(#staff-company-wrapper-sub)');

                                    $firstWrapper.removeClass('h');
                                    staff.popupEmployees.get($firstWrapper.find('.staff-people-wrapper:first').data('id'), function() {
                                        staff.popupEmployees.scrollbar().scrollToY(0);
                                    });
                                } else {
                                    $currentPopup.find('.staff-company-wrapper').addClass('h');
                                    staff.popupEmployees.get(null, function() {
                                        staff.popupEmployees.scrollbar().scrollToY(0);
                                    });
                                }
                            }
                        }, 500);
                    }
                },
                subscribe: function (element, onlySelect, multiselect) {
                    var submit = $currentPopup.find('#staffpeople-scrollbar-submit'),
                        el = $(element),
                        id = el.data('id'),
                        hidden = submit.find('.item[data-id="' + el.data('id') + '"]');

                    multiselect = (typeof multiselect == 'undefined') ? true : multiselect;

                    if (multiselect) {
                        // CHECKBOX
                        el.toggleClass('subscribe');
                    } else {
                        // Сбрасываю все значения
                        submit.empty();

                        // RADIO
                        var $scroll = $currentPopup.find('#staffpeople-scrollbar-wrap');
                        // Удаляю галочки
                        $scroll.find('.icn-approve:not(.h)').addClass('h');
                        // Удаляю фон выделения
                        $scroll.find('.staff-people-item.subscribe').removeClass('subscribe');
                        // Устанавливаю фон выделения
                        el.addClass('subscribe');
                    }

                    // ищем такого же сотрудника на странице
                    if (el.hasClass('subscribe')) {
                        $currentPopup.find('#staffpeople-scrollbar-wrap').find('.staff-people-item[data-id="' + id + '"]').not(el)
                            .addClass('subscribe')
                            .find('.icn-approve').removeClass('h');

                        manualCheckedId.push(id);
                    } else {
                        $currentPopup.find('#staffpeople-scrollbar-wrap').find('.staff-people-item[data-id="' + id + '"]').not(el)
                            .removeClass('subscribe')
                            .find('.icn-approve').addClass('h');

                        var ind;
                        if ((ind = manualCheckedId.indexOf(id)) > -1) {
                            manualCheckedId.splice(ind, 1);
                        }
                    }

                    if (!onlySelect) {
                        if (hidden.length && multiselect) {
                            hidden.remove();
                        } else {
                            submit.append('<input type="hidden" data-id="' + el.data('id') + '" data-ava-small="' + el.find('.staff-ava-small').attr('src') + '" data-name="' + el.find('.staff-people-name a').text() + '" class="item" value="' + (+el.hasClass('subscribe')) + '">');
                        }

                        staff.popupEmployees.updateFooter();
                        staff.popupEmployees.focus();
                    }
                },
                filter: function (el) {
                    var text = $.trim($currentPopup.find('#search-employees').val()),
                        filter = $(el).data('filter'),
                        id = $currentPopup.find('.staff-company-wrapper .staff-people-wrapper:not(.h)').data('id');

                    if (!id || id == 'sub') {
                        id = $currentPopup.find('.staff-company-wrapper:not(#staff-company-wrapper-sub):first .staff-people-wrapper').data('id');
                    }

                    if (searchFilter != filter) {
                        $currentPopup.find('.small-tab').removeClass('on');
                        $(el).addClass('on');
                        searchFilter =  $(el).data('filter');
                        $currentPopup.find('.staff-people-wrapper').empty();

                        if (text) {
                            $currentPopup.find('.staff-company-wrapper').addClass('h');
                        }

                        if (filter == 'sub') {
                            id = filter;
                            $currentPopup.find('#staff-company-wrapper-sub').removeClass('h');
                        } else {
                            $currentPopup.find('#staff-company-wrapper-sub').addClass('h');
                        }

                        staff.popupEmployees.get(id, function() {
                            staff.popupEmployees.scrollbar().scrollToY(0);
                        });
                    }

                    staff.popupEmployees.focus();
                    return false;
                },
                toggleCompany: function(id) {
                    var $wrapper = $currentPopup.find('#staff-company-wrapper-' + id + ' .staff-people-wrapper');
                    
                    $wrapper.toggleClass('h');

                    if (!$currentPopup.find('#staff-company-wrapper-' + id + ' .staff-people-item > *').length) {
                        staff.popupEmployees.get(id);
                    }

                    staff.popupEmployees.scrollbar().update();
                    staff.popupEmployees.focus();
                },
                checkAllCompany: function(event, id, all) {
                    var $wrapper = $currentPopup.find('#staff-company-wrapper-' + id);
                    event.stopPropagation();
                    event.preventDefault();

                    if (!$wrapper.find('.staff-people-item > *').length) {
                        staff.popupEmployees.get(id, function() {
                            $wrapper.find('.staff-people-wrapper').removeClass('h');
                            $wrapper.find('.staff-people-item').each(function (ind, el) {
                                if (all && !$(el).hasClass('subscribe') || !all && $(el).hasClass('subscribe')) {
                                    staff.popupEmployees.subscribe(el);
                                }
                            });
                        });
                    } else {
                        $wrapper.find('.staff-people-wrapper').removeClass('h');
                        $wrapper.find('.staff-people-item').each(function (ind, el) {
                            if (all && !$(el).hasClass('subscribe') || !all && $(el).hasClass('subscribe')) {
                                staff.popupEmployees.subscribe(el);
                            }
                        });
                    }
                },
                prepareFooter: function(label, noLabel, plural) {
                    footerLabel = label;
                    footerNoLabel = noLabel;
                    footerPlural = plural;

                    if (!footerNoLabel) {
                        footerNoLabel = fsLang.g('Нажмите') + ' <div class="key-wrapper">&uarr;</div> <div class="key-wrapper">&darr;</div> ' + fsLang.g('и') + ' <div class="key-wrapper">space</div> ' + fsLang.g('для выбора');
                    }

                    $currentPopup.find('.popup-footer').append('<div class="popup-employees-footer ellipsis"></div>');
                    staff.popupEmployees.updateFooter();
                },
                updateFooter: function() {
                    st(function() {
                        $('.iPopup').each(function() {
                            var countAll = $(this).find('#staffpeople-scrollbar-submit .item').length;
                            $(this).find('.popup-employees-footer').html(countAll ? footerLabel + ' <b>' + countAll + '</b> ' + fsLang.plural(countAll, footerPlural) : footerNoLabel);
                        });
                    }, 0);
                },
                updateSelected: function() {
                    var $item;
                    $('#staffpeople-scrollbar-submit .item').each(function() {
                        $item = $('#staff-people-item-' + $(this).data('id'));
                        if ($(this).val() == 1 && !$item.hasClass('subscribe') || $(this).val() == 0 && $item.hasClass('subscribe')) {
                            staff.popupEmployees.subscribe($item[0], true);
                        }
                    });
                },
                focus: function() {
                    $currentPopup.find('#search-employees').focus().select();
                },
                render: function(e) {
                    // ищем элемент в обработанных вариантах
                    var $item = $currentPopup.find('#staffpeople-scrollbar-submit').find('.item[data-id="' + e.id + '"]'),
                        isChecked;

                    if ($item.length) {
                        isChecked = $item.val() == 1;
                    } else {
                        isChecked = e.checked;
                    }

                    return '<div class="staff-people-item ' + (isChecked ? 'subscribe' : '') + ' ' + (e.checked ? 'light' : '') + '" id="staff-people-item-' + e.id + '" onclick="staff.popupEmployees.subscribe(this, false, ' + (e.multiselect ? true : false) + ');" data-id="' + e.id + '" data-multiselect="' + (e.multiselect ? 1 : 0) + '">' +
                            '<div class="staff-people-img"><img class="staff-ava staff-ava-small" src="' + e.image + '" /></div>' +
                            '<div class="staff-people-about">' +
                                '<div class="staff-people-name">' +
                                    '<a target="_blank" href="' + e.url + '" onclick="return false">' + e.name + '</a>' +
                                    (e.icon === false ? '' : '<i class="icn icn-info" onmouseover="helpTooltip.show(this, \'' + e.icon + '\');" onmouseout="helpTooltip.hide(this);"></i>') +
                                '</div>' +
                            '</div>' +
                           '</div>';
                }
            };
        }(),
        indicatePassword: function(el) {
            var getScore = function(password) {
                    var score = 0;

                    if (password) {
                        var letters = {};

                        for (var i = 0; i < password.length; i++) {
                            letters[password[i]] = (letters[password[i]] || 0) + 1;
                            score += 2.5 / letters[password[i]];
                        }

                        var tests = {
                            digits: /\d/.test(password),
                            latLower: /[a-z]/.test(password),
                            latUpper: /[A-Z]/.test(password),
                            nonWords: /\W/.test(password)
                        };

                        var passed = 0;

                        for (var test in tests) {
                            passed += tests[test] ? 1 : 0;
                        }

                        score = Math.min(100, parseInt(score + (passed - 1) * 40));
                    }

                    return score;
                },
                score = getScore(el.value),
                length = el.value.length,
                label = '',
                barColor = '',
                labelColor = '',
                illegal = /[^a-z0-9!@#$%^*()_\-+]/i.test(el.value),
                allowedSymbols = ['!', '@', '#', '$', '%', '^', '*', '(', ')', '_', '-', '+'],
                redColor = '#e56966',
                blueColor = '#87cbe2',
                greenColor = '#83b180';

            if (illegal) {
                label = fsLang.g('Пароль содержит запрещенные символы (можно использовать латинские буквы, цифры и символы %s)', [allowedSymbols.join(', ')]);
                barColor = redColor;
                labelColor = redColor;
                score = 100;
            } else if (length > 0) {
                if (length < 6) {
                    label = fsLang.g('Пароль слишком короткий (минимум 6 символов)');
                    barColor = redColor;
                    labelColor = redColor;
                    score = 100;
                } else if (length > 20) {
                    label = fsLang.g('Пароль слишком длинный (максимум 20 символов)');
                    barColor = redColor;
                    labelColor = redColor;
                    score = 100;
                } else {
                    if (score < 60) {
                        label = fsLang.g('Пароль слишком простой');
                        barColor = redColor;
                        labelColor = redColor;
                    } else if (score < 80) {
                        label = fsLang.g('Хороший пароль');
                        barColor = blueColor;
                        labelColor = blueColor;
                    } else {
                        label = fsLang.g('Отличный пароль');
                        barColor = greenColor;
                        labelColor = greenColor;
                    }
                }
            }

            var bar = $(el).parent().find('.password-strength');
            bar.find('.strength-bar').css({ width: score + '%', background: barColor }).data('score', score);
            bar.find('.strength-label').css({ color: labelColor }).html(label);

            return score;
        },
        collapseTabsWrapIfNeeded: function() {
            var $header = $('#tab-names'),
                maxWidth = $header.parent().width() - 45, // 45 - ширина кнопки
                maxElemInRow = 0,
                currentWidth = 0;

            $header.find('.tab-name').addClass('ellipsis').each(function(i, e) {
                currentWidth += $(e).outerWidth();

                if (currentWidth >= maxWidth) {
                    maxElemInRow = i - 1; // предпоследний элемент
                    return false;
                }
            });

            if (maxElemInRow > 0) {
                $header.append('<div id="tabs-toggle-collapse" class="tab-name dropdown-item unselectable" onclick="ddb.onclick(this, event);">\n' +
                    '<div class="dropdown-item-title"><i class="icn icn-gray icn-down"></i><i class="icn icn-gray icn-up"></i></div>' +
                    '<div class="dropdown-box"></div>' +
                '</div>');

                $header.find('.tab-name:not(#tabs-toggle-collapse)').filter(':gt(' + maxElemInRow + ')')
                    .removeClass('tab-name ellipsis').addClass('ddb-item')
                    .detach().appendTo("#tabs-toggle-collapse .dropdown-box");

                var $toggleButton = $('#tabs-toggle-collapse');

                if ($toggleButton.find('.dropdown-box .ddb-item.on').length) {
                    $toggleButton.addClass('on');
                }

                $header.addClass('flex').css('justify-content: space-evenly;');
            }
        }
    };
}();

try {
    staticManager.done('app_team_js_base_js');
} catch(e) {}